
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/globalColorVars.scss';
@import '../../style/font-family.scss';
@import '../../style/buttons.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.mobile-search-filter-dropdown {
  display: flex;
  flex-direction: row;
  column-gap: 30px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  @include media-breakpoint-down(lg) {
    .search-box-wrapper {
      margin-bottom: 0 !important;
      width: 100%;
    }
  }

  .mobile-buttons-wrap {
    display: inline-flex;
    width: max-content;
    column-gap: 30px;
    row-gap: 30px;

    button {
      display: inline-flex;
      color: $button-primary-color;
      font-weight: 500;
      align-items: center;
      border: 1px solid $button-primary-color;
      padding: 12px 20px;
      width: max-content;
      @include media-breakpoint-up(lg) {
        display: none;
      }
      &:hover {
        background: $button-primary-bright-color;
        border: 1px solid $button-primary-color !important;
      }
      &:focus,
      &:active {
        background: transparent !important;
        border: 1px solid $button-primary-color !important;
      }
      svg {
        color: $button-primary-color;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .mobile-buttons-wrap {
      width: 100%;
      margin-top: 30px;

      button {
        flex: 1 1 100%;
        justify-content: center;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .mobile-search-filter-dropdown {
    display: block;

    .mobile-buttons-wrap {
      display: none;
    }
    .search-box-wrapper {
      margin-bottom: 20px !important;
      width: 100%;
    }
  }
}

.categories-parent-wrap {
  margin-top: 10px;
  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }
}
@include media-breakpoint-up(sm) {
  .categories-parent-wrap.categories-select-wrap {
    display: none;
  }
}
.talks-filter-block {
  .filter-title-block {
    display: flex;
    justify-content: space-between;
    //padding-bottom: 10px;
    align-items: center;

    .filters-heading {
      font-size: 22px;
      font-weight: 500;
      display: inline-block;
      padding-bottom: 0;
      margin-bottom: 20px;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    .reset-filters {
      float: right;
      font-size: 14px;
      margin: 0;

      @include media-breakpoint-down(lg) {
        display: none;
      }

      svg {
        color: $icon-grey;
      }

      button {
        font-size: 14px;
        font-weight: 500;
        color: $primary-text;
        margin-left: 5px;
      }
    }
  }

  .categories-parent-wrap,
  .categories-select-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 30px;
    width: auto;
    justify-content: flex-start;

    // hide block by default
    @include media-breakpoint-down(lg) {
      display: none;

      // if the class 'show-cats' is added by the state toggle function  show block
      &.show-cats,
      &.show-cats-select {
        display: flex;
      }
    }

    @include media-breakpoint-up(lg) {
      flex-direction: column;
    }
    .category-block-wrap {
      @include media-breakpoint-down(lg) {
        width: calc(25% - 22.5px);
      }

      margin-bottom: 0;
      .category-title {
        font-size: 18px;
        font-weight: 500;
        @include media-breakpoint-down(lg) {
          border-bottom: 1px solid $links-divider-color !important;
        }
      }
      .category-name {
        line-height: 1.5;
        margin-top: 15px;

        &.last-item {
          padding-bottom: 30px;
          @include media-breakpoint-up(lg) {
            border-bottom: 1px solid $links-divider-color !important;
          }
          margin-bottom: 15px;
        }
        button {
          position: relative;
          color: $primary-text;
          display: block;
          text-align: left;
          width: 100%;

          &:hover,
          &:active {
            font-weight: 500;
            color: $primary-text;
          }

          span {
            float: right;
            position: relative;
            z-index: -1;
          }

          &.active {
            font-weight: 500;
          }
        }
      } // category name

      &:last-of-type {
        .category-name {
          border-bottom: 0 !important;
        }
      }
    }
  }
  .no-divider {
    border: 0;
  }

  @include media-breakpoint-down(sm) {
    .mobile-filters-toggle {
      display: none !important;
    }
  }
  @include media-breakpoint-up(sm) {
    .mobile-filters-toggle-select {
      display: none !important;
    }
    .mobile-filters-toggle {
      display: inline-flex;
    }
  }

  // Search box styles.
  .search-box-wrapper {
    position: relative;
    margin-bottom: 20px;

    input {
      background: #f9f9f9 0 0 no-repeat padding-box;
      border-radius: 3px;
      border: 1px solid $border-color-bright !important;
      padding: 13px 30px 13px 12px;
      width: 100%;
      border: none;

      &:focus {
        background-color: #f9f9f9;
        outline: 0;
        box-shadow: none;
      }
      &::placeholder {
        color: #757575;
        opacity: 0.5;
      }
    }
    svg {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 12px;
      top: 13px;
    }
  }

  // categories-select-wrap manages the category titles & names as BS select elements

  .categories-select-wrap {
    .category-block-wrap {
      position: relative;
      width: calc(50% - 15px);
      margin-bottom: 10px;

      .category-title {
        border-bottom: 0 !important;
        pointer-events: none;
        position: absolute;
        transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -webkit-transition: all 0.2s ease;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        font-size: 16px;
        margin: 0;
        font-weight: 400;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 50px);
        overflow: hidden;
        transform-origin: 0 0;
        padding-left: 16px;
      }

      .category-name {
        -moz-box-align: center;
        align-items: center;
        border-radius: 0px;
        box-shadow: none;
        cursor: default;
        display: flex;
        flex-wrap: wrap;
        -moz-box-pack: justify;
        justify-content: space-between;
        min-height: 38px;
        outline: 0px !important;
        position: relative;
        transition: 100ms;
        box-sizing: border-box;
        border-width: 0px 0px 1px;
        border-style: none none solid;
        border-color: currentcolor currentcolor rgb(204, 211, 218);
        border-image: none;
      }
    }
  }
} // close talks-filter-block
