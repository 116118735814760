
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/globalColorVars';

@import '../../style/buttons';
@import '../../style/font-family';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.modal-box {
  > div {
    width: 100%;
    height: max-content;
    position: relative;
    text-align: start;
	padding-bottom: 60px;
  }

  .modal-close {
    border-bottom: none;
    padding-right: 20px;
    padding-top: 22px;
    padding-bottom: 24px;
    > button {
      position: absolute;
      right: 5px;
      top: 10px;
	  background: none;
	  border: none;
    }
  }

  .modal-title {
    margin-top: 14px;
    font-size: 26px;
    color: $primary-text;
    @include roboto-medium;
  }

  .modal-container {
    padding: 0 60px;
  }

  .footer {
    font-size: 14px;
  }
}
