
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use '@bt-branding/colors';

.label {
    pointer-events: none;
    position: absolute;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    font-size: 16px;
    margin: 0px;
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 50px);
    overflow: hidden;
    transform-origin: 0px 0px;
    padding-left: 16px;

    &.floating-label {
        transform: scale(0.75) translateY(-0.75rem) translateX(0.375rem) !important;
        top: 18px;
        color: colors.$bt-secondary;
    }

}

.chevrondown-icon {
    width: 24px !important;
    height: 24px !important;
}