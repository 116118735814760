
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use '../../style/container.scss';
@import '../../style/font-family.scss';
@import '../../style/globalColorVars.scss';
@import '../../style/globalVars.scss';
@import '../../style/buttons.scss';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.container {
  max-width: 1440px !important;
}
// Layout only
.channel-talk-block-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  max-width: 1440px !important;
  gap: 20px;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  .talkblock-sidebar {
    margin-bottom: 30px;
    flex: 0 0 100%;

    @include media-breakpoint-up(lg) {
      margin: 0;
      flex-basis: 240px;
      max-width: 240px;
    }
  }

  .talkblock-main {
    flex: 1 1 auto;
  }
}
// styling

// No results display for talks grid

.no-results-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  .warning-icon {
    margin-bottom: 30px;
    svg {
      color: $icon-grey;
      height: 64px;
      width: 64px;
    }
  }

  .results-message {
    margin-bottom: 20px;

    p {
      @include roboto-regular;
      font-size: 26px;
      margin: 0;
    }
  }
  button.no-results-button {
    @include roboto-medium;
    font-weight: 700;
    font-size: 20px;
    border-color: $icon-blue-embed;
    padding: 6px 12px !important;

    &:hover,
    &:focus {
      background: #e3f4ff;
      border-color: $icon-blue-embed !important;
    }

    svg {
      color: $icon-blue-embed;
    }
  }
  button:focus {
    border-color: $border-color-bright !important;
  }
}
