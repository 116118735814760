
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/buttons.scss';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.tabs-block {
  display: flex;
  flex-direction: row;
  gap: 13px;
  button.tabs-button {
    @include media-breakpoint-down(sm) {
      padding: 8px 12px !important;
    }
  }
  .active:focus {
    border-color: $border-color-bright !important;
  }
}
